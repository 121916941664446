import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import '@/plugins'
import GlobalComponents from "./globalComponents"
import store from './store/index'
// widget
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
// styles
import "./assets/scss/main.scss"

// axios
const baseURL = 'https://gs3d.whitewebstudio.com/api/'
Vue.prototype.$api = axios.create({ baseURL })

Vue.use(GlobalComponents)
Vue.use(vueCustomElement)

App.store = store
Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.customElement('gs3d-widget', App)
} else {
  new Vue({
    store,
    render: h => h(App),
  }).$mount('#gs3d')
}
