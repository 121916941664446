import Vue from 'vue';
import VueSimpleSVG from "vue-simple-svg"
import VueKonva from 'vue-konva';
import VueScrollTo from 'vue-scrollto';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)
Vue.use(VueScrollTo)
Vue.use(VueKonva)
Vue.use(VueSimpleSVG)
Vue.use(PerfectScrollbar)