import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { merge } from 'lodash'

// TODO standardize with axios.create() to avoid code duplications; refactor store
const baseURL = 'https://gs3d.whitewebstudio.com/api/';

Vue.use(Vuex)

const state = {
  projects: [],
  currentProject: null,
  layers: [],
  currentLayer: null,
  currentProduct: null,
  catalog: [],
  allCatalog: null,
  catalogFilter: '',
  selectedFilters: [],
  catalogLoader: false,
  categoriesOpen: false,
  categoriesGroupsOpen: false,
  filtersOpen: false,
  catalogProducts: [],
  inspirationModal: false
}

const getters = {
  currentProjectId: state => state.currentProject?.project.id,
  currentProject: state => state.currentProject,
  currentLayer: state => state.currentLayer,
  currentProduct: state => state.currentProduct,
};

const mutations = {
  saveProjects(state, val) {
    state.projects = val
  },
  setCurrentProject(state, val) {
    state.currentProject = val
  },
  setCurrentLayer(state, val) {
    state.currentLayer = val
  },
  setCurrentProduct(state, val) {
    state.currentProduct = val
  },
  updateCurrentProduct(state, update) {
    const projectId = state.currentProject.project.id;
    const layerId = state.currentLayer.id;
    const productId = state.currentProduct.id;
    const project = state.projects.find(p => p.project.id === projectId);
    const layer = project.layers.find(l => l.id === layerId);
    const product = layer.products.find(p => p.id === productId);

    merge(product, update);
    merge(state.currentProduct, update);
  },
  saveLayers(state, val) {
    state.layers = val
  },
  saveAllProducts(state, val) {
    state.catalogProducts = val
  },

  saveAllCatalog(state, val) {
    state.allCatalog = val
  },
  setCatalogLoader(state, val) {
    state.catalogLoader = val
  },
  setCatalogFilter(state, val) {
    state.catalogFilter = val
  },
  selectFilters(state, val) {
    // if (!state.selectedFilters.includes(val)) {
      state.selectedFilters.push(val)
    // }
  },
  removeSelectedFilter(state, val) {
    let ind = state.selectedFilters.indexOf(val)
    // if (state.selectedFilters.includes(val)) {
      state.selectedFilters.splice(ind, 1)
    // }
  },
  toggleCategories(state, val) {
    state.categoriesOpen = val
    state.categoriesGroupsOpen = false
    state.filtersOpen = false
  },
  toggleCategoriesGroups(state, val) {
    state.categoriesGroupsOpen = val
    state.categoriesOpen = false
    state.filtersOpen = false
  },
  toggleFilters(state, val) {
    state.filtersOpen = val
    state.categoriesOpen = false
    state.categoriesGroupsOpen = false
  },
  setCatalogProducts(state, val) {
    state.catalogProducts = val
  }
}

const actions = {
  getLayers({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({url: `${baseURL}constructor/project/layer/get?project_id=${id}`, data: {project_id:id}, method: 'GET'})
        .then((res) => {
          if (res.status === 200) {
            commit('saveLayers', res.data.layers)

            if(res.data.layers.length && state.currentLayer) {
              let currentLayerId = state.currentLayer.id
              let layer = res.data.layers.filter((el)=>{
                return el.id === currentLayerId
              })[0]
              commit('setCurrentLayer', layer)
            } else {
              commit('setCurrentLayer', {})
            }
          }
          resolve(res);
        })
        .catch(err => {reject(err)})
    })
  },
  setCatalogFilter({commit, dispatch}, slug) {
    // let filters =
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}catalog${slug || ''}`, {}, {withCredentials: true})
        .then(res => {
          if(res.status === 200) {
            commit('saveAllCatalog', res.data)
            commit('saveCatalog', res.data.products.data)
            dispatch('saveCatalogProducts', res.data.products.data)
          }
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },

  getCatalog({commit}) {
    // if(slug) {
    //   commit('saveCatalog', [])
    //   commit('setCatalogLoader', true)
    // }
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}catalog`, {}, {withCredentials: true})
        .then(res => {
          if(res.status === 200) {
            commit('saveAllProducts', res.data.products.data)

            // commit('saveAllCatalog', res.data)
            // commit('saveCatalog', res.data.products.data)
            // commit('setCatalogFilter', slug || '')
            // dispatch('saveCatalogProducts', res.data.products.data)
          }
          resolve(res)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getSortedCatalog({commit}, slug) {
    if(slug) {
      commit('saveCatalog', [])
    }
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}catalog${slug || ''}`, {}, {withCredentials: true})
        .then(res => {
          if(res.status === 200) {
            // commit('saveAllCatalog', res.data)
            commit('saveCatalog', res.data.products.data)
          }
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },

  saveCatalogProducts({state, commit}, products) {
    let arr = []
    arr = state.catalogProducts.concat(products)
    // console.log(arr);
    commit('setCatalogProducts', arr)
  },

  getProjects({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}constructor/project/get`,{}, {withCredentials: true})
        .then(res => {
          if (res.status === 200) {
            commit('saveProjects', res.data.projects)
          }
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },
  updateLayers({state, dispatch}) {
    let projectId = state.currentProject.project.id
    dispatch('getProjects')
    dispatch('getLayers', projectId)
  },
  updateProjectImage(_, { projectId, image }) {
    const data = {
      project_id: projectId,
      image,
    };
    return axios.post(`${baseURL}constructor/project/update-image`, data).then(res => res.data);
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
