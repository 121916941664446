<template>
  <div>
    <div class="wrapper">
      <div class="generator-header">
        <div class="f-center">
          <svg class="icon-logo">
            <use xlink:href="#icon-logo"></use>
          </svg>
          <div v-if="currentProject" class="f-center" style="margin-left: 20px">
            <h3>{{currentProject.project.project_name}}</h3>
            <div @click="setCurrentProject(null)" class="link" style="margin-left: 20px">Back</div>
          </div>
        </div>
      </div>

      <Editor v-if="currentProject"
              @filter="$emit('filter', $event)"
              @set-layer="setCurrentLayer($event)"
              @to-inspiration="toInspiration($event)"/>

      <!-- Projects-->
      <div v-else class="generator">
        <div class="generator-image" style="opacity: 0.8">
          <picture>
            <source srcset="https://gs3d.whitewebstudio.com/img/constructor/room_placeholder.png">
            <img src="https://gs3d.whitewebstudio.com/img/constructor/room_placeholder.png" alt="gs3d">
          </picture>
        </div>
        <div class="generator-packs">
          <div class="generator-packs__add">
            <div v-if="projects.length" class="projects-list">
              <h3>Projects list</h3>
              <div v-for="item in projects"
                   :key="item.project.id"
                   class="projects-list__item">
                <div @click="setCurrentProject(item)" style="cursor: pointer">{{item.project.project_name}}</div>
                <div @click="removeProject(item.project.id)" class="clear"><i class="icon icon-close"></i></div>
              </div>
            </div>

            <br>
            <h3>Add new project</h3>
            <div class="input-field" :class="{error: formError}">
              <input v-model="name" type="text" placeholder="Name" @keyup.enter="addProject">
            </div>
            <button @click="addProject" class="btn">Add project</button>
          </div>
        </div>
      </div>
    </div>

    <Modal v-if="modal" :send-data="modalData" @hide="modalHide" />
  </div>
</template>

<script>
  export default {
    name: "Generator",
    components: {
      Editor: () => import('../Editor/Editor.vue'),
      Modal: () => import('./Modal'),
    },
    props: {
      added_product: {
        type: Object
      }
    },
    data() {
      return {
        name: '',
        formError: false,
        images: [],
        draggingId: null,
        prevOffsetX: 0,
        prevOffsetY: 0,
        modal: false,
        modalData: null
      }
    },
    computed: {
      projects() {
        return this.$store.state.projects
      },
      currentProject() {
        return this.$store.state.currentProject
      },
      currentProduct() {
        return this.$store.state.currentProduct
      },
      currentLayer() {
        return this.$store.state.currentLayer
      },
      queryProjectId() {
        let params = (new URL(document.location)).searchParams
        return parseInt(params.get('project_id'))
      },
      queryProjectItem() {
        return this.projects.filter((el)=>{
          return el.project.id === this.queryProjectId
        })[0]
      }
    },
    methods: {
      // Projects
      getProjects() {
        this.$store.dispatch('getProjects')
      },
      setCurrentProject(item) {
        this.$store.commit('setCurrentProject', item)
        if (item !== null) {
          this.getLayers(item.project['id'])

          if (item.layers.length) {
            this.setCurrentLayer(item.layers[0])
          } else {
            this.addLayer()
          }
        }
      },
      setCurrentLayer(item) {
        if (item) {
          this.$store.commit('setCurrentProduct', null)
          this.$store.commit('setCurrentLayer', item)
        }
      },
      addProject() {
        if (this.name.length) {
          let data = {
            project_name: this.name
          }
          this.$api.post(`/constructor/project/add?project_name=${this.name}`, data)
            .then(res => {
              if (res.status === 200) {
                this.name = ''
                this.formError = false
              }
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.getProjects()
            })
        } else {
          this.formError = true
        }
      },
      removeProject(id) {
        this.$api.delete(`/constructor/project/delete?id=${id}`, {id: id})
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.getProjects()
          })
      },
      addLayer() {
        let data = {
          project_id: this.currentProject.project.id,
          position: 1
        }
        this.$api.post(`/constructor/project/layer/add`, data)
          .then(res => {
            if (res.status === 200) {
              let layer = res.data.layer
              this.setCurrentLayer(layer)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.getLayers(this.currentProject.project.id)
          })
      },

      removeLayer(id) {
        this.$api.delete(`/constructor/project/layer/delete?id=${id}`, {id: id})
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.getLayers(this.currentProject.project.id)
          })
      },
      getLayers(id) {
        if (id) {
          this.$store.dispatch('getLayers', id)
        }
      },

      addProduct(product) {
        let image = new Image(),
          scale = product.images[0].scale/100
        image.src = `https://gs3d.whitewebstudio.com/storage/${product.images[0].image}`

        if(this.currentLayer) {
          let data = {
            layer_id: this.currentLayer.id,
            product_id: product.id,
            coordinate_x: 0,
            coordinate_y: 0,
            width: image.width * scale,
            height: image.height * scale,
            product_angle: 0,
            rotation: 0
          }
          // console.log('data', data);
          this.$api.post(`/constructor/project/layer/product/add `, data)
            .finally(() => {
              if (this.currentProject.project.id) {
                this.$store.dispatch('getLayers', this.currentProject.project.id)
              }
            })
        }
      },
      toInspiration(data) {
        this.modalData = data
        this.modal = true
      },
      modalHide() {
        this.modalData = null
        this.modal = false
      }
    },
    watch: {
      added_product() {
        this.addProduct(this.added_product)
      },
      queryProjectItem() {
        this.setCurrentProject(this.queryProjectItem)
      }
    }
  }
</script>
