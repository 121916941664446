<template>
  <div class="widget-modal">
    <div class="widget-modal__container">
      <div class="widget-modal__inner" v-click-outside="hide">
        <div @click="hide" class="close-btn"><i class="icon icon-close"></i></div>
        <h3>Add to inspiration</h3>
        <div class="input-field" :class="{error: formError}">
          <input v-model="name" type="text" placeholder="Name" @keyup.enter="add">
        </div>
        <button @click="add" class="btn">Add</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Modal",
    props: {
      sendData: Object
    },
    data() {
      return {
        name: '',
        formError: false
      }
    },
    methods: {
      add() {
        if (this.name.length) {
          let data = {
            ...this.sendData,
            name: this.name
          }

          this.$api.post(`api/constructor/inspiration/save`, data)
            .then(res => {
              if (res.status === 200) {
                this.name = ''
                this.formError = false
                this.hide()
              }
            })
            .catch(error => {console.log(error)})
            .finally(() => {
              this.$store.dispatch('updateLayers')
            })
        } else {
          this.formError = true
        }
      },
      hide() {
        this.$emit('hide')
      }
    }
  }
</script>
