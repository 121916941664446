import draggable from 'vuedraggable'
import Loader from "@/components/Global/Loader.vue"

const GlobalComponents = {
  install(Vue) {
    Vue.component("draggable", draggable)
    Vue.component("Loader", Loader)
  }
}

export default GlobalComponents
