import { mapGetters } from 'vuex';
import { CANVAS_WIDTH, CANVAS_HEIGHT } from '@/helpers/editor-defaults';

function texteditorStylesDefaults() {
  return {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    color: 'green',
  };
}


export default {
  data() {
    return {
      currentShape: '',
      transformShape: '',
      cropTransformShape: '',
      selectedCropStrategy: 'center-middle',
      activeTextEdit: false,
      texteditorContainerStyles: texteditorStylesDefaults(),
    };
  },
  computed: {
    ...mapGetters([
      'currentLayer',
      'currentProduct',
    ]),
  },
  methods: {
    /**
     * Transform actions
     */
    updateTransformer(params) {
      // console.log('params', params);
      let transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()
      let selectedNode = ""
      if (typeof params === "undefined" || params.length === 0) {
        selectedNode = stage.findOne("." + this.transformShape)
      } else {
        selectedNode = params
        transformerNode.nodes(selectedNode)
        return
      }
      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        if (selectedNode.attrs.type === "text") {
          this.transformer = {
            ignoreStroke: true,
            enabledAnchors: ["middle-left", "middle-right"],
            // rotationSnaps: [0, 90, 180, 270],
            // limit transformer size
            boundBoxFunc: (oldBox, newBox) => {
              if (newBox.width < 50) {
                return oldBox
              }
              return newBox
            },
          }
        } else {
          this.transformer = {
            ignoreStroke: true,
            anchorStroke: '#000',
            anchorFill: 'transparent',
            anchorSize: 5,
            borderStroke: '#7B7B7B',
            borderDash: [2, 3]
          }
        }
        // attach to another node
        transformerNode.nodes([selectedNode])
        this.shapeControl = true
      } else {
        // remove transformer
        transformerNode.nodes([])
        this.texteditor.visible = false
        this.shapeControl = false
      }
    },

    transformText(e) {
      // if (e.type === "transformstart") {
      //
      // } else if (e.type === "transform") {
      const rect = this.stage.layer.shape.find(
        (r) => r.name === this.selectedShapeName
      )
      let transformerNode = this.$refs.transformer.getNode()
      if (rect && transformerNode.getActiveAnchor() !== "rotater") {
        rect.width = Math.max(e.target.width() * e.target.scaleX(), 50)
        rect.height = null
        e.target.scaleX(1)
        e.target.scaleY(1)
      }
      // }
    },

    enableCropTransform() {
      if (this.currentShape) {
        this.cropTransformShape = this.currentShape
        this.$nextTick(() => {
          this.updateCropTransformer()
        })
      }
    },

    onImageTransform() {
      const shape = this.findShape(this.cropTransformShape);

      shape.setAttrs({
        scaleX: 1,
        scaleY: 1,
        width: shape.width() * shape.scaleX(),
        height: shape.height() * shape.scaleY(),
      });
      this.applyCrop(shape.getAttr('lastCropUsed'));
    },

    updateCropTransformer(params) {
      let cropTransformerNode = this.$refs.cropTransformer.getNode()
      const stage = cropTransformerNode.getStage()
      let selectedNode = ""
      if (typeof params === "undefined" || params.length === 0) {
        selectedNode = stage.findOne("." + this.cropTransformShape)
      } else {
        selectedNode = params
        cropTransformerNode.nodes(selectedNode)
        return
      }
      if (selectedNode === cropTransformerNode.node()) {
        return
      }
      if (selectedNode) {
        this.selectedCropStrategy = selectedNode.attrs.lastCropUsed;
        if (selectedNode.attrs.type === "text") {
          this.cropTransformer = {
            ignoreStroke: true,
            enabledAnchors: ["middle-left", "middle-right"],
            // rotationSnaps: [0, 90, 180, 270],
            // limit transformer size
            boundBoxFunc: (oldBox, newBox) => {
              if (newBox.width < 50) {
                return oldBox
              }
              return newBox
            },
          }
        } else {
          this.cropTransformer = {
            ignoreStroke: true,
            anchorStroke: 'red',
            anchorFill: 'transparent',
            anchorSize: 6,
            borderStroke: 'orange',
            borderDash: [3, 4],
            rotateEnabled: false
          }
        }
        // attach to another node
        cropTransformerNode.nodes([selectedNode])
        this.shapeControl = true
      } else {
        // remove transformer
        cropTransformerNode.nodes([])
        this.shapeControl = false
      }
    },

    // function to calculate crop values from source image, its visible size and a crop strategy
    getCrop(image, size, clipPosition = this.selectedCropStrategy) {
      const width = size.width;
      const height = size.height;
      const aspectRatio = width / height;

      let newWidth;
      let newHeight;

      const imageRatio = image.width / image.height;

      if (aspectRatio >= imageRatio) {
        newWidth = image.width;
        newHeight = image.width / aspectRatio;
      } else {
        newWidth = image.height * aspectRatio;
        newHeight = image.height;
      }

      let x = 0;
      let y = 0;
      if (clipPosition === 'left-top') {
        x = 0;
        y = 0;
      } else if (clipPosition === 'left-middle') {
        x = 0;
        y = (image.height - newHeight) / 2;
      } else if (clipPosition === 'left-bottom') {
        x = 0;
        y = image.height - newHeight;
      } else if (clipPosition === 'center-top') {
        x = (image.width - newWidth) / 2;
        y = 0;
      } else if (clipPosition === 'center-middle') {
        x = (image.width - newWidth) / 2;
        y = (image.height - newHeight) / 2;
      } else if (clipPosition === 'center-bottom') {
        x = (image.width - newWidth) / 2;
        y = image.height - newHeight;
      } else if (clipPosition === 'right-top') {
        x = image.width - newWidth;
        y = 0;
      } else if (clipPosition === 'right-middle') {
        x = image.width - newWidth;
        y = (image.height - newHeight) / 2;
      } else if (clipPosition === 'right-bottom') {
        x = image.width - newWidth;
        y = image.height - newHeight;
      } else if (clipPosition === 'scale') {
        x = 0;
        y = 0;
        newWidth = width;
        newHeight = height;
      } else {
        console.error(
          new Error('Unknown clip position property - ' + clipPosition)
        );
      }

      return {
        cropX: x,
        cropY: y,
        cropWidth: newWidth,
        cropHeight: newHeight,
      };
    },

    applyCrop(cropStrategy = this.selectedCropStrategy, customShape = null) {
      const shape = customShape || this.findShape(this.cropTransformShape);
      shape.setAttr('lastCropUsed', cropStrategy);
      const crop = this.getCrop(shape.image(), { width: shape.attrs.width, height: shape.attrs.height }, cropStrategy);
      shape.setAttrs(crop);

      return { shape };
    },

    rotateProduct() {
      const product = this.currentProduct;
      let imgs360 = [];

      if (product.product.colors.length) {
        let currentColor = product.product.colors.filter((el) => {
          return el.id === product.color_id
        })[0]
        imgs360 = currentColor.images_360
      } else if (product.product.images360.length) {
        imgs360 = product.product.images360
      }
      if(imgs360.length > 1) {
        if(imgs360.length === 2) {
          this.angleIdx = 0
        } else if (this.angleIdx === imgs360.length) {
          this.angleIdx = 1
        }
        this.angleIdx++;

        const name = `${this.projectId}_${product.layer_id}_${product.id}`;
        const shape = this.findShape(name);
        const scale = product.product.scale / 100;
        const img = new window.Image();

        img.src = imgs360[this.angleIdx - 1]

        img.onload = () => {
          if (shape) {
            shape.setAttrs({
              image: img,
              width: img.width * scale,
              height: img.height * scale,
            })
          }
          let changes = {
            ...product,
            width: img.width * scale,
            height: img.height * scale,
            product_angle: this.angleIdx-1
          }
          this.editProduct(changes)
        };
      }
    },
    enableTransform() {
      if (this.currentShape) {
        this.transformShape = this.currentShape
        this.$nextTick(() => {
          this.updateTransformer()
        })
      }
    },

    flipProduct() {
      this.updateTransformer()
      let shape = this.findShape(this.currentShape);

      if (shape) {
        const shapeScale = shape.scale();
        const shapeFlipped = shapeScale.x < 0;
        const shapePosition = shape.position();
        const shapeSize = shape.size();
        const newShapeX = shapeFlipped ? shapePosition.x - shapeSize.width : shapePosition.x + shapeSize.width;

        shape.setAttrs({
          x: newShapeX,
          scaleX: -shapeScale.x,
        });

        // TODO can be changed to more relevant event, ex. "dragend"
        shape.fire('transformend');
      }
    },


    /**
     * Text actions
     */
    textEditor(e) {
      this.updateTransformer();
      const name = e.target.attrs.name
      const rect = this.findShape(name)
      const editor = this.texteditor;

      if (e.type === "dblclick") {
        this.preventHideStrokes = true;

        this.currentShape = e.target.attrs.name;

        this.updateTransformer()

        const rectPosition = rect.absolutePosition();

        Object.assign(this.texteditorContainerStyles, {
          top:  `${rectPosition.y}px`,
          left: `${rectPosition.x}px`,
          width: `${rect.attrs.width}px`,
          height: `${rect.attrs.height}px`,
          color: rect.attrs.fill, // TODO probably should be backgroundColor
        });

        editor.height = rect.getHeight();
        editor.text = rect.attrs.text;
        editor.fontSize = rect.attrs.fontSize;
        editor.lineHeight = rect.lineHeight();
        editor.rotation = rect.attrs.rotation;

        editor.visible = true;
        rect.setAttrs({visible: false})

        this.$nextTick(() => {
          this.$refs.texteditor.focus()
        })
      } else if (e.type === "blur") {
        alert('blur')
        // rect.setAttrs({visible: true})
        // editor.height = rect.attrs.height
        //
        // editor.visible = false
        // this.$nextTick(() => {
        //   this.$refs.texteditor.focus();
        //   this.updateTransformer();
        //
        // });
      }
    },

    changeTextEditor(e) {
      const rect = this.findShape(this.currentShape);
      rect.setAttrs({
        text: e.target.innerText,
        height: e.target.height,
      })
    },

    textAction(e) {
      const shapeName = this.currentShape
      const rect = this.findShape(shapeName)
      const editor = this.texteditor

      if (e.type === "blur") {
        this.activeTextEdit = false

        this.updateTransformer()

        editor.text = rect.attrs.text;
        editor.fontSize = rect.attrs.fontSize;
        // editor.lineHeight = rect.attrs.lineHeight;
        editor.rotation = rect.attrs.rotation;

        editor.visible = false;
        rect.setAttrs({visible: true})

        this.updateCurrentProduct({
          text: editor.text,
          product: { text: editor.text }
        });

        rect.fire('transformend')

        this.preventHideStrokes = false;
      } else if (e.type === "focus") {

        this.activeTextEdit = true
        // this.texteditor.visible = true;
        rect.setAttrs({visible: false})
        // alert('focus')
        // transformerNode.nodes([])
      }
    },


    /**
     * Add actions
     */
    addText() {
      if (this.currentLayer) {
        let text = {
          type: "text",
          text: "Some text",
          layer_id: this.currentLayer.id,
          coordinate_x: CANVAS_WIDTH / 2 - 75,
          coordinate_y: CANVAS_HEIGHT / 2,
          width: 150,
          height: 50,
          font_size: 28,
          font_color: 'red'
        }
        // if (this.currentShape) {
        // this.transformShape = this.currentShape
        // this.$nextTick(() => {
        //   this.updateTransformer()
        // })
        // }
        this.addProduct(text)
      }
    },

    addLine() {
      if (this.currentLayer) {
        let shape = {
          type: 'figure',
          figure_type: 'line',
          layer_id: this.currentLayer.id,
          coordinate_x: CANVAS_WIDTH / 2 - 50,
          coordinate_y: CANVAS_HEIGHT / 2 - 50,
          width: 150,
          height: 150,
          rotation: 0
        }
        this.addProduct(shape)
      }
    },

    addRect() {
      if (this.currentLayer) {
        let shape = {
          type: 'figure',
          figure_type: 'rect',
          layer_id: this.currentLayer.id,
          coordinate_x: CANVAS_WIDTH / 2 - 75,
          coordinate_y: CANVAS_HEIGHT / 2 - 75,
          width: 150,
          height: 150,
          rotation: 0
        }
        this.addProduct(shape)
      }
    },

    addCircle() {
      if (this.currentLayer) {
        let shape = {
          type: 'figure',
          figure_type: 'circle',
          layer_id: this.currentLayer.id,
          coordinate_x: CANVAS_WIDTH / 2 - 75,
          coordinate_y: CANVAS_HEIGHT / 2 - 75,
          width: 150,
          height: 150,
          rotation: 0
        }
        this.addProduct(shape)
      }
    },

    addArrow() {
      if (this.currentLayer) {
        let shape = {
          type: 'figure',
          figure_type: 'arrow',
          layer_id: this.currentLayer.id,
          coordinate_x: CANVAS_WIDTH / 2 - 75,
          coordinate_y: CANVAS_HEIGHT / 2 - 75,
          width: 150,
          height: 150,
          rotation: 0
        }
        this.addProduct(shape)
      }
    },
  },
};
