<template>
  <div class="toolbar">
    <ul>
      <li v-for="(item, i) of filters" :key="i" @click="$emit('catalog-filter', item.filter)">
        <img :src="require('@/assets/img/'+item.icon)" :alt="item.title" :title="item.title">
      </li>
    </ul>

    <ul>
      <li @click="$emit('enable-crop')" :class="{disabled: !currentProduct || currentProduct.type === 'text'}"><img src="@/assets/img/icon-cut.png" alt="cut" title="Crop"></li>
      <li @click="$emit('rotate-product')" :class="{disabled: !currentShape}"><img src="@/assets/img/rotate-icon.png" alt="rotate" title="Rotate"></li>
      <li @click="$emit('enable-transform')" :class="{disabled: !currentShape}"><img src="@/assets/img/icon-scale.png" alt="resize" title="Resize"></li>
      <li @click="$emit('flip-product')" :class="{disabled: currentProduct && currentProduct.type === 'text' }"><img src="@/assets/img/icon-symmetry.png" alt="flip" title="Flip"></li>
      <li ref="uploadImage" @click="uploadImage"><img src="@/assets/img/icon-picture.png" alt="image" title="Upload image"></li>
      <li>
        <img src="@/assets/img/icon-image.png" alt="shape" title="Add shape">
        <ul>
          <li ref="addLine" @click="$emit('add-line')"><span class="line">&nbsp;</span></li>
          <li ref="addRect" @click="$emit('add-rect')"><span class="rect">&nbsp;</span></li>
          <li ref="addCircle" @click="$emit('add-circle')"><span class="circle">&nbsp;</span></li>
        </ul>
      </li>
      <li @click="$emit('add-text')"><img src="@/assets/img/icon-text.png" alt="text" title="Add text"></li>
      <li @click="$emit('add-arrow')"><img src="@/assets/img/icon-arrow.png" alt="arrow" title="Add arrow"></li>
    </ul>

    <input
      hidden
      ref="uploadImage"
      type="file"
      accept="image/*"
      @change="onFileChanged($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const filters = [
  {
    title: '3D',
    icon: 'icon-3d.png',
    filter: 'rand=1'
  }, {
    title: 'Wall',
    icon: 'icon-wall.png',
    filter: 'walls'
  }, {
    title: 'Textures',
    icon: 'icon-parquet.png',
    filter: 'textures'
  }, {
    title: 'Windows',
    icon: 'icon-windows.png',
    filter: 'windows'
  }, {
    title: 'Doors',
    icon: 'icon-door.png',
    filter: 'doors'
  }, {
    title: 'Molding and Millwork',
    icon: 'icon-plinth.png',
    filter: 'molding-and-millwork'
  },
];

export default {
  name: 'EditorToolbar',
  props: {
    currentShape: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filters,
    };
  },
  computed: {
    ...mapGetters([
      'currentProjectId',
      'currentProject',
      'currentLayer',
      'currentProduct',
    ]),
    layers() {
      return this.$store.state.layers
    },
  },
  methods: {
    uploadImage() {
      this.$refs.uploadImage.click()
    },
    onFileChanged(e) {
      let URL = window.webkitURL || window.URL
      let url = URL.createObjectURL(e.target.files[0])
      let img = new window.Image()
      img.src = url
      img.onload = () => {
        let formData = new FormData()
        formData.append('type', 'image')
        formData.append('layer_id', this.currentLayer.id)
        formData.append('image', e.target.files[0])
        formData.append('width', img.width / 2)
        formData.append('height', img.height / 2)
        formData.append('coordinate_x', 0)
        formData.append('coordinate_y', 0)

        if (this.currentLayer) {
          const headers = { 'Content-Type': 'multipart/form-data' };
          this.$api.post(`/constructor/project/layer/product/add `, formData, { headers })
            .finally(() => this.$store.dispatch('getLayers', this.currentProjectId))
        }
      }
    },
  },
};
</script>
