<template>

  <perfect-scrollbar>
    <div class="generator-packs">
      <draggable v-if="layers.length"
                 v-model="list"
                 v-bind="dragOptions"
                 draggable=".layer-pack"
                 @start="onTouchLayer"
                 @end="changeLayerPosition"
                 style="display: flex">
        <LayerPack v-for="(item, i) in list"
                   :key="item.position"
                   :badge="i+1"
                   :layer="item"
                   @set-layer="setCurrentLayer(item)"
                   @hide-layer="hideLayer($event)"
                   @item-select="selectItem($event)"
                   @hide-product="hideProduct($event)"
                   @change-color="changeProductColor($event)"
                   @remove="removeLayer(item.id)"/>
      </draggable>
      <div class="layer-pack layer-pack-empty">
        <div @click="addLayer" class="add-layer f-col-center">
          <svg class="icon-layers">
            <use xlink:href="#icon-layers"></use>
          </svg>
          <div>Add layer</div>
        </div>
      </div>

    </div>
  </perfect-scrollbar>
</template>

<script>
  export default {
    name: "GeneratorPacks",
    components: {
      LayerPack: () => import('../Generator/LayerPack.vue'),
    },
    props: {
      project: {
        type: Object
      }
    },
    data() {
      return {
        name: '',
        formError: false,
        list: this.project.layers.map((el) => {
          return el
        })
      }
    },
    computed: {
      currentProject() {
        return this.$store.state.currentProject
      },
      currentProduct() {
        return this.$store.state.currentProduct
      },
      currentLayer() {
        return this.$store.state.currentLayer
      },
      layers() {
        return this.$store.state.layers
      },
      dragOptions() {
        return {
          animation: 0,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        }
      }
    },
    methods: {
      onTouchLayer(e) {
        let layer = e.item['_underlying_vm_']
        this.setCurrentLayer(layer)
        // console.log(layer)
      },
      setCurrentLayer(item) {
        this.$emit('set-layer', item)
      },
      selectItem(e) {
        this.$emit('item-select', e)
      },
      hideProduct(e) {
        this.$emit('hide-product', e)
      },
      changeProductColor(e) {
        this.$emit('change-color', e)
      },
      hideLayer(e) {
        this.$emit('hide-layer', e)
      },
      // Projects
      setCurrentProject(item) {
        this.$store.commit('setCurrentProject', item)
        if (item !== null) {
          this.getLayers(item.project['id'])
          this.$store.commit('setCurrentLayer', item.layers[0])
        }
      },
      addProject() {
        if (this.name.length) {
          let data = {
            project_name: this.name
          }
          this.$api.post(`/constructor/project/add?project_name=${this.name}`, data)
            .then(res => {
              if (res.status === 200) {
                this.name = ''
                this.formError = false
              }
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.$emit('update')
            })
        } else {
          this.formError = true
        }
      },
      removeProject(id) {
        this.$api.delete(`/constructor/project/delete?id=${id}`, {id: id})
          .then(res => {
            if (res.status === 200) {
              // console.log(res);
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$emit('update')
          })
      },

      // Layers
      addLayer() {
        let pos = this.layers.length + 1
        let data = {
          project_id: this.currentProject.project.id,
          position: pos
        }
        this.$api.post(`/constructor/project/layer/add`, data)
          .then(res => {
            if (res.status === 200) {
              // console.log(res.data);
              let layer = res.data.layer
              this.$store.commit('setCurrentLayer', layer)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.getLayers(this.currentProject.project.id)
          })
      },
      changeLayerPosition(e) {
        let layerId = e.item['_underlying_vm_'],
          newIndex = e.newDraggableIndex

        // console.log(e.item, layerId, newIndex)
        this.editLayer(layerId, newIndex)
      },
      editLayer(layer, pos) {
        let data = {
          id: layer.id,
          project_id: layer.project_id,
          position: pos
        }
        this.$api.put(`/constructor/project/layer/edit`, data)
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$store.dispatch('updateLayers')
          })
      },
      removeLayer(id) {
        this.$api.delete(`/constructor/project/layer/delete?id=${id}`, {id: id})
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.getLayers(this.currentProject.project.id)
          })
      },
      getLayers(id) {
        if (id) {
          this.$store.dispatch('getLayers', id)
        }
      },
      editProduct(id, layer, prod, x, y) {
        let data = {
          id: id,
          layer_id: layer,
          position: 1,
          product_id: prod,
          coordinate_x: x,
          coordinate_y: y,
          product_angle: 0
        }
        // console.log(data);
        this.$api.put(`/constructor/project/layer/product/edit`, data)
          .then(res => {
            if (res.status === 200) {
              console.log(res)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            // this.$store.dispatch('getLayers', this.currentProject.project.id)
          })
      }
    },
    watch: {
      layers() {
        this.list = this.layers.map((el) => {
          return el
        })
      }
    }
  }
</script>
