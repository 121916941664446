<template>
  <div class="layer-pack"
       @click.prevent="setCurrentLayer(layer)"
       :class="{active: currentLayer && currentLayer.id === layer.id }">
    <perfect-scrollbar v-if="layer.products.length">
      <draggable class="layer-pack__main"
                 v-model="list"
                 v-bind="dragOptions"
                 draggable=".layer-pack__item"
                 @start="isDragging = true"
                 @end="changePosition">
        <div v-for="item in list"
             :key="item.position"
             @click.stop="selectProduct(item)"
             :class="{active: currentProduct && currentProduct.id === item.id}"
             class="layer-pack__item">
          <div class="image" v-if="item.type === 'product'">
            <picture>
              <source :srcset="getProductImg(item, item.color_id)">
              <img :src="getProductImg(item, item.color_id)" alt="gs3d">
            </picture>
          </div>
          <div class="image picture" v-else-if="item.type === 'image'">
            <picture>
              <source :srcset="item.product.image">
              <img :src="item.product.image" alt="gs3d">
            </picture>
          </div>
          <div class="image text" v-else-if="item.type === 'text'">
            <picture>
              <source srcset="@/assets/img/icon-text.png">
              <img src="@/assets/img/icon-text.png" alt="gs3d">
            </picture>
          </div>
          <div v-else class="image" :class="item.product.figure_type">
            <span></span>
          </div>
          <div class="title">
            <div class="name">
              <span v-if="item.type === 'product'">{{item.product.name}}</span>
              <span v-else-if="item.type === 'image' || item.type === 'text'">({{item.type}})</span>
              <span v-else>({{item.product.figure_type}})</span>
            </div>
            <div class="title-row">
              <svg v-if="item.type === 'product'"  @click="hideProduct(item)"
                   :class="{active: +item.hide === 1}"
                   class="icon-hidden">
                <use xlink:href="#icon-eye"></use>
              </svg>

              <div @click="duplicateProduct(item)" class="copy-icon">
                <img src="@/assets/img/icon-copy.png" alt="copy" title="Copy">
              </div>
              <VueSlickCarousel v-if="item.type === 'product' && item.product.colors.length"
                                v-bind="sliderSettings"
                                class="color-slider">
                <div v-for="color in item.product.colors"
                     :key="color.id"
                     @click="changeProductColor(item, color)"
                     class="color-slider__item"
                     :class="{active: item.color_id === color.id}"
                     :style="{'background-color': color.color}">&nbsp;
                </div>
              </VueSlickCarousel>
            </div>
          </div>
          <div @click="removeProduct(item.id)" class="clear"><i class="icon icon-close"></i></div>
        </div>
      </draggable>
    </perfect-scrollbar>
    <div v-else class="layer-pack__main empty">Products list is empty <br>Add items from catalog</div>
    <div class="layer-pack__footer">
      <div class="layer-badge"><span>{{badge}}</span></div>
      <span class="link">Save set\image</span>
      <span @click.stop="hideLayer(layer)">
        <svg class="icon-hidden"
             :class="{active: +layer.hide === 1}">
          <use xlink:href="#icon-eye"></use>
        </svg>
      </span>
      <span @click="duplicateLayer(layer)"><svg class="icon-compare"><use xlink:href="#icon-compare"></use></svg></span>
      <span @click="$emit('remove')" class="link">Remove</span>
    </div>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'

  export default {
    name: "LayerPack",
    components: {
      VueSlickCarousel
    },
    props: {
      layer: {
        type: Object,
        required: true
      },
      badge: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        list: this.layer.products.map((el) => {
          return el
        }),
        sliderSettings: {
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          arrows: true
        }
      }
    },
    computed: {
      currentLayer() {
        return this.$store.state.currentLayer
      },
      currentProject() {
        return this.$store.state.currentProject
      },
      currentProduct() {
        return this.$store.state.currentProduct
      },
      dragOptions() {
        return {
          animation: 0,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        }
      }
    },
    methods: {
      changePosition(e) {
        this.changeProductPosition(e.item['_underlying_vm_'], e.newDraggableIndex)
        // console.log('target', e.item['_underlying_vm_'])
      },
      sort() {
        this.list = this.list.sort((a, b) => a.order - b.order)
      },

      // layers
      setCurrentLayer(layer) {
        this.$emit('set-layer', layer)
      },
      hideLayer(layer) {
        let visible = +layer.hide === 0 ? true : false
        let data = {
          id: layer.id,
          position: layer.position,
          visible: visible
        }
        this.$emit('hide-layer', data)
        this.editLayer(data)
      },
      editLayer(layer) {
        let data = {
          id: layer.id,
          hide: layer.visible,
          project_id: this.currentProject.project.id,
          position: layer.position,
        }
        this.$api.put('/constructor/project/layer/edit', data)
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$store.dispatch('getLayers', this.currentProject.project.id)
          })
      },
      duplicateLayer(layer) {
        let pos = layer.position++
        let data = {
          id: layer.id,
          project_id: this.currentProject.project.id,
          position: pos
        }
        this.$api.post(`/constructor/project/layer/duplicate`, data)
          .then(res => {
            if (res.status === 200) {
              console.log(res)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$store.dispatch('getLayers', this.currentProject.project.id)
          })
      },

      // products
      selectProduct(product) {
        this.$emit('item-select', product)
      },
      hideProduct(product) {
        this.$emit('hide-product', product)
        let changes = {
          ...product,
          hide: +product.hide === 0 ? 1 : 0
        }
        this.editProduct(changes)
      },
      changeProductPosition(product, newIndex) {
        let changes = {
          ...product,
          position: newIndex
        }
        this.editProduct(changes)
      },
      duplicateProduct(product) {
        let data = {
          id: product.id,
          layer_id: this.currentLayer.id,
          project_id: this.currentProject.project.id
        }
        this.$api.post(`/constructor/project/layer/product/duplicate`, data)
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$store.dispatch('updateLayers')
          })
      },
      getProductImg(product, color) {
        if(product.type === 'product') {
          if (product.product.colors.length) {
            let currentImg
            if (color > 0) {
              currentImg = product.product.colors.filter((el) => {
                return el.id === color
              })[0]['main_image']
            } else currentImg = product.product.colors[0]['main_image']
            // console.log(product, color, currentImg)
            return currentImg
          } else if (product.product.images360[0] && product.product.images360[0].length) {
            return product.product.images360[0]
          } else return product.product.image
        }
      },
      changeProductColor(product, color) {
        let changes = {
          ...product,
          color_id: color.id
        }
        this.$store.commit('setCurrentProduct', product)
        this.$emit('change-color', changes)
        this.editProduct(changes)
      },
      editProduct(data) {
        this.$api.put('/constructor/project/layer/product/edit', data)
          .then(res => {
            if (res.status === 200) {
              let updatedCurrentProduct = {
                ...this.currentProduct,
                ...res.data.layer
              }
              this.$store.commit('setCurrentProduct', updatedCurrentProduct)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$store.dispatch('updateLayers')
          })
      },
      removeProduct(id) {
        this.$emit('remove-product', id)
        this.$api.delete(`/constructor/project/layer/product/delete?id=${id}`, {id: id})
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.$store.dispatch('getLayers', this.currentProject.project.id)
          })
      }
    },
    watch: {
      layer() {
        this.list = this.layer.products.map((el) => {
          return el
        })
      }
    }
  }
</script>
