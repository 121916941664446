<template>
  <div class="loader">
    <div class="loader-icon"></div>
  </div>
</template>

<script>
  export default {
    name: "Loader"
  }
</script>