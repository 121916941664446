<template>
  <div id="gs3d" class="gs3d-generator">
    <Loader v-if="loading"/>
    <Generator :added_product="added_product"
               @filter="$emit('filter', $event)"/>
    <SvgSprite/>
  </div>
</template>

<script>
  import SvgSprite from "./components/Global/SvgSprite";
  import Generator from './components/Generator/Generator'

  export default {
    name: 'App',
    components: {
      SvgSprite,
      Generator
    },
    props: {
      added_product: {
        type: Object
      }
    },
    data() {
      return {
        loading: true
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      load() {
        this.getProjects()
      },
      getProjects() {
        this.$store.dispatch('getProjects')
          .then(() => this.loading = false)
      }
    }
  }
</script>
