<template>
  <div class="text-editor-toolbar">
    <quill-editor
        ref="textEditor"
        :options="editorOption"
        @change="onEditorChange"
    />
  </div>
</template>

<script>
  import { quillEditor } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'

  export default {
    name: 'TextEditorToolbar',
    components: {
      quillEditor
    },
    data() {
      return {
        editorOption: {
          modules: {
            toolbar: [
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'color': [] }, { 'background': [] }],
              ['clean']
            ],
          }
        }
      }
    },
    methods: {
      onEditorChange(value) {
        alert(value)
      }
    },
    computed: {
      editor() {
        return this.$refs.textEditor.quill
      }
    }
  }
</script>

<style lang="scss" scoped>
  .example {
    display: flex;
    flex-direction: column;

    .editor {
      height: 40rem;
      overflow: hidden;
    }
  }
</style>