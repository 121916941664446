<template>
  <select
    class="ImageCropStrategiesSelect"
    @change="onChange"
  >
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option"
      :selected="value === option"
    >
      {{ option }}
    </option>
  </select>
</template>

<script>
const options = [
  'left-top',
  'center-top',
  'right-top',
  'left-middle',
  'center-middle',
  'right-middle',
  'left-bottom',
  'center-bottom',
  'right-bottom',
];

export default {
  name: 'ImageCropStrategiesSelect',
  props: {
    value: {
      type: String,
      default: options[0],
    },
  },
  data() {
    return {
      options,
    };
  },
  methods: {
    onChange($event) {
      const { value } = $event.target;
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>
