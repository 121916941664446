<template>
  <div>
    <div class="generator-btn">
      <button
        class="tag"
        :disabled="currentProject.in_inspiration"
        @click="addToInspiration"
      >
        Add to inspirations
      </button>
      <button class="tag" @click="saveStage">Save as</button>
    </div>

    <div class="generator-editor">
      <div class="canvas_container" ref="canvasContainer">
        <TextEditorToolbar v-if="activeTextEdit"/>

        <ImageCropStrategiesSelect
          v-if="cropTransformShape"
          v-model="selectedCropStrategy"
          class="canvas_container__crop-strategies"
          @change="onCropStrategySelect"
        />

        <div class="stage_container" ref="stageContainer">
          <v-stage
            ref="mainStage"
            :config="mainStageConfig"
            @dragstart="handleOnDragStart"
            @dragend="handleOnDragEnd"
            @mousedown="handleOnMouseDown"
            @click="handleOnMouseClick"
          >
            <v-layer ref="layer">
              <v-rect :config="bgRectConfig"/>

              <v-group
                v-for="(layer, i) of shapes"
                :key="i"
                :config="{
                  name: `layer_${layer.id}`,
                  visible: +layer.hide === 0
                }"
              >
                <template v-for="item in getLayerShapes(layer)">
                  <!--  TODO add drag & pointer events listeners  -->
                  <v-image
                    v-if="item.type === 'product'"
                    :key="item.id"
                    type="image"
                    :ref="item.shape.name"
                    :draggable="item.shape.draggable"
                    :name="item.shape.name"
                    :rotation="item.shape.rotation"
                    :visible="item.shape.visible"
                    :x="+item.shape.x"
                    :y="+item.shape.y"
                    :width="+item.shape.width"
                    :height="+item.shape.height"
                    :image="convertBlobToImg(item)"
                    @transform="onImageTransform"
                    @transformend="handleTransformEnd"
                  />
                  <v-image
                    v-else-if="item.type === 'image'"
                    :key="item.id"
                    type="image"
                    :ref="item.shape.name"
                    :draggable="item.shape.draggable"
                    :name="item.shape.name"
                    :rotation="item.shape.rotation"
                    :visible="item.shape.visible"
                    :x="+item.shape.x"
                    :y="+item.shape.y"
                    :width="+item.shape.width"
                    :height="+item.shape.height"
                    :image="convertBlobToImg(item)"
                    @transform="onImageTransform"
                    @transformend="handleTransformEnd"
                  />
                  <v-rect
                    v-else-if="item.type === 'rect'"
                    :key="item.id"
                    :config="item.shape"
                    :ref="item.shape.name"/>
                  <v-circle
                    v-else-if="item.type === 'circle'"
                    :key="item.id"
                    :config="item.shape"
                    :ref="item.shape.name"/>
                  <v-line
                    v-else-if="item.type === 'line'"
                    :key="item.id"
                    :config="item.shape"
                    :ref="item.shape.name"
                  />
                  <v-arrow
                    v-else-if="item.type === 'arrow'"
                    :key="item.id"
                    :config="item.shape"
                    :ref="item.shape.name"/>
                  <v-text
                    v-else-if="item.type === 'text'"
                    :key="item.id"
                    :config="item.shape"
                    @dblclick="textEditor"
                    @transformstart="transformText"
                    @transform="transformText"
                    @transformend="handleTransformEnd"
                    :ref="item.shape.name"
                  />
                </template>
                <input type="text"/>
              </v-group>

              <v-transformer ref="transformer" :config="transformer" />
              <v-transformer ref="cropTransformer" :config="cropTransformer" />
            </v-layer>
          </v-stage>

          <div
            v-if="texteditor.visible"
            :style="texteditorContainerStyles"
            class="texteditor"
          >
            <div
              ref="texteditor"
              contenteditable
              :style="{ 'font-size': texteditor.fontSize + 'px', lineHeight: texteditor.lineHeight }"
              class="textarea-editor"
              @blur="textAction"
              @focus="textAction"
              @input="changeTextEditor"
            >{{ texteditor.text }}</div>
          </div>
        </div>
      </div>

      <EditorToolbar
        :current-shape="currentShape"
        @catalog-filter="filterCatalog"
        @enable-crop="enableCropTransform"
        @rotate-product="rotateProduct"
        @enable-transform="enableTransform"
        @flip-product="flipProduct"
        @add-line="addLine"
        @add-rect="addRect"
        @add-circle="addCircle"
        @add-text="addText"
        @add-arrow="addArrow"
      />

      <GeneratorPacks
        :project="currentProject"
        @item-select="selectProduct($event)"
        @hide-product="hideProduct($event)"
        @change-color="changeProductColor($event)"
        @hide-layer="hideLayer($event)"
        @set-layer="setLayer($event)"
      />
    </div>

    <div>
      <img
        v-for="(item, i) in imgPreload"
        :src="item"
        :key="i"
        :alt="i"
        hidden
      />
    </div>
  </div>
</template>

<script>
import Konva from 'konva';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import { CANVAS_WIDTH, CANVAS_HEIGHT } from '@/helpers/editor-defaults';
import stageActionsMixin from '@/mixins/editor/shape-actions';

function stageDefaults() {
  return {
    config: {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    },
    layer: {
      config: {
        scale: {
          x: 1,
          y: 1,
        },
      },
      canvasConfig: {
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        x: 0,
        y: 0,
        name: "canvas",
        draggable: false,
      },
      shape: [],
    },
  };
}

function texteditorDefaults() {
  return {
    focus: false,
    visible: false,
    text: "Some text",
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    fontSize: 0,
    textAlign: "left",
    lineHeight: "inherit",
  };
}

export default {
  name: "Editor",
  components: {
    EditorToolbar: () => import('./EditorToolbar.vue'),
    GeneratorPacks: () => import('./GeneratorPacks.vue'),
    ImageCropStrategiesSelect: () => import('./ImageCropStrategiesSelect.vue'),
    TextEditorToolbar: () => import('./TextEditorToolbar.vue'),
  },
  mixins: [stageActionsMixin],
  data() {
    return {
      stg: null,
      idxShape: 0,
      mainStageConfig: {
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT
      },
      bgRectConfig: {
        x: 0,
        y: 0,
        fill: '#F2F2F2',
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        name: "editor",
        draggable: false
      },
      shapes: [],
      texteditor: texteditorDefaults(),
      stage: stageDefaults(),
      shapeControl: false,
      boundingClient: "",
      transformer: {
        ignoreStroke: true
      },
      cropTransformer: {
        ignoreStroke: true
      },
      selectedShapeProp: {
        top: "",
        bottom: "",
        props: {},
      },
      preventHideStrokes: false,
      file_convert: "",
      angleIdx: 1,
      shapeZIndex: 0,
      editAbortController: null,
    };
  },
  computed: {
    ...mapGetters([
      'currentProjectId',
      'currentProject',
      'currentLayer',
      'currentProduct',
    ]),
    projectId() {
      return this.currentProjectId;
    },
    layers() {
      return this.$store.state.layers
    },
    imgPreload() {
      let imgs = this.layers.map((i)=>{
        return i.products.map((el)=>{
          if(el.product && el.product.images360) {
            return el.product.images360
          }
        })
      })
      return imgs.flat(2)
    },
  },
  watch: {
    selectedShapeProp() {
      console.log(this.selectedShapeProp)
    },
    currentLayer() {
      this.setDraggable(this.currentLayer)
    },
    currentProduct() {
      // console.log(this.currentProduct);
      // this.selectProduct(this.currentProduct)
    },
    layers() {
      this.shapes = this.layers
      this.applyShapesAttrs();
    }
  },
  created() {
    window.addEventListener("resize", this.setStageSize)
    window.addEventListener("resize", this.setCanvasProp)
    this.setStageSize()
    this.shapes = this.currentProject.layers
    this.$nextTick().then(this.applyShapesAttrs)
  },
  mounted() {
    this.stg = this.$refs.mainStage.getStage()
    const stageContainer = this.$refs.stageContainer
    // this.idxShape = this.stage.layer.shape.length
    this.boundingClient = stageContainer.getBoundingClientRect()
    this.setCanvasProp()
    this.setStageSize()
    this.setDraggable(this.currentLayer)
  },
  destroyed() {
    window.removeEventListener("resize", this.setStageSize)
    window.removeEventListener("resize", this.setCanvasProp)
    this.clearWorkspace()
  },
  methods: {
    ...mapActions([
      'updateProjectImage',
    ]),
    ...mapMutations([
      'updateCurrentProduct',
    ]),

    clearWorkspace() {
      this.shapes = []
      this.$store.commit('setCurrentProject', null)
      this.$store.commit('setCurrentLayer', null)
      this.$store.commit('setCurrentProduct', null)
    },
    getLayerShapes(layer) {
      return layer.products.map((el) => {
        const item = {
          id: el.id,
          layer_id: el.layer_id,
          product_id: el.product.id,
          shape: {
            name: `${this.projectId}_${el.layer_id}_${el.id}`,
            x: +el.coordinate_x,
            y: +el.coordinate_y,
            rotation: el.rotation,
            flip: el.flip,
            clip_position: el.clip_position,
            scaleX: 1,
            scaleY: 1,
            height: +el.height,
            width: +el.width,
            draggable: true,
            visible: +el.hide === 1 ? false : true,
          },
        };

        if (el.type === 'product') {
          item.type = 'product'
          item.name = el.product.name
          item.shape.image = el.product.image
          item.shape.product_angle = el.product_angle
        } else if (el.type === 'image') {
          item.type = 'image'
          item.shape.image = el.product.image
        } else if (el.type === 'text') {
          item.type = 'text'
          item.shape.text = el.product.text
          item.shape.fontSize = el.product.font_size
          item.shape.fill = el.product.font_color
        } else {
          if (el.product.figure_type === 'circle' || el.product.figure_type === 'rect') {
            item.type = el.product.figure_type
            item.shape.stroke = '#6088d1'
            item.shape.strokeWidth = 2
            item.shape.strokeScaleEnabled = false
          } else if (el.product.figure_type === 'line') {
            item.type = el.product.figure_type
            item.shape.stroke = '#6088d1'
            item.shape.strokeWidth = 3
            item.shape.strokeScaleEnabled = false
            item.shape.points = [0, 0, 100, -100]
          } else if (el.product.figure_type === 'arrow') {
            item.type = el.product.figure_type
            item.shape.fill = '#6088d1'
            item.shape.stroke = '#6088d1'
            item.shape.strokeWidth = 3
            item.shape.strokeScaleEnabled = false
            item.shape.points = [0, 0, 100, -100]
            item.shape.pointerLength = 10
            item.shape.pointerWidth = 10
          }
        }
        return item
      })
    },
    setLayer(item) {
      this.hideAllStrokes()
      if (item) {
        this.$emit('set-layer', item)
      }
    },
    findShape(name) {
      return this.stg.findOne(`.${name}`);
    },
    setDraggable(layer) {
      let groups = this.stg.find('Group')
      // console.log(groups);
      if (groups.length && layer) {
        let groupsShapes = groups.slice(0, -1).map((el) => {
          if (el.children.length) return el.children
        })
        if (groupsShapes.length > 1 && layer) {
          let allShapes = [].concat.apply([], groupsShapes)
          // reset all draggables
          // console.log(allShapes)
          if (allShapes.length) {
            allShapes.forEach((el) => {
              if (el) {
                el.setAttrs({
                  draggable: false
                })
              }
            })
          }

          let layerShapes = this.stg.findOne('.layer_' + layer.id)
          // console.log(layerShapes);

          // set draggables to current layer
          // console.log(layerShapes.children)
          if (layerShapes.children && layerShapes.children.length) {
            layerShapes.children.forEach((el) => {
              // console.log(el);
              el.setAttrs({
                draggable: true
              })
            })
          }
        }
      }
    },
    hideProduct(item) {
      let name = `${this.projectId}_${item.layer_id}_${item.id}`
      let shape = this.findShape(name)
      if (shape) {
        shape.setAttrs({
          visible: !shape.visible()
        })
      }
    },
    getTotalBox(boxes) {
      let minX = Infinity;
      let minY = Infinity;
      let maxX = -Infinity;
      let maxY = -Infinity;

      boxes.forEach((box) => {
        minX = Math.min(minX, box.x);
        minY = Math.min(minY, box.y);
        maxX = Math.max(maxX, box.x + box.width);
        maxY = Math.max(maxY, box.y + box.height);
      })
      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
      }
    },
    onDragMove(e) {
      // console.log('dragmove', e.target)
      let name = e.target.attrs.name
      let shape = this.findShape(name)
      const absPos = shape.getAbsolutePosition()
      const offsetX = e.target.attrs.x - absPos.x;
      const offsetY = e.target.attrs.y - absPos.y;


      // we total box goes outside of viewport, we need to move absolute position of shape
      const newAbsPos = {...absPos};
      if (e.target.attrs.x < 0) {
        newAbsPos.x = -offsetX;
      }
      if (e.target.attrs.y < 0) {
        newAbsPos.y = -offsetY;
      }
      if (e.target.attrs.x + e.target.attrs.width > this.stg.width()) {
        newAbsPos.x = this.stg.width() - e.target.attrs.width - offsetX;
      }
      if (e.target.attrs.y + e.target.attrs.height > this.stg.height()) {
        newAbsPos.y = this.stg.height() - e.target.attrs.height - offsetY;
      }
      shape.setAbsolutePosition(newAbsPos)
    },
    changeProductColor(item) {
      let currentImg,
        productColors = this.currentProduct.product.colors,
        name = `${this.projectId}_${item.layer_id}_${item.id}`,
        shape = this.findShape(name)

      if (productColors.length) {
        if (item.color_id > 0) {
          currentImg = productColors.filter((el) => {
            return el.id === item.color_id
          })[0]['main_image']
        }
      }
      if (shape) {
        let img = new window.Image()
        img.src = currentImg
        shape.setAttrs({
          image: img
        })
      }
    },
    hideLayer(layer) {
      let name = `layer_${layer.id}`
      let shape = this.findShape(name)
      if (shape) {
        shape.setAttrs({
          visible: layer.visible
        })
      }
    },
    onCropStrategySelect(cropStrategy) {
      const shape = this.applyCrop(cropStrategy)?.shape;
      if (shape) {
        shape.fire('transformend');
      }
    },

    // simulated zIndex
    moveToTop(layer, id) {
      let currentLayer = this.shapes.filter((el) => {
        return el.id === layer
      })[0]

      if (currentLayer.products.length) {
        let items = currentLayer.products,
          item = items.find((i) => i.id === id),
          idx = items.indexOf(item)

        this.shapeZIndex = idx
        console.log(item)

        if(item.hide !== 1) {
          // remove from the list:
          items.splice(idx, 1);
          // add to the top
          items.push(item);
        }
      }
    },
    selectProduct(item) {
      if (item !== null) {
        let layerId = this.currentLayer.id,
          itemId = item['id'],
          projectId = this.projectId
        let name = `${projectId}_${layerId}_${itemId}`

        this.moveToTop(layerId, itemId)

        this.transformShape = ''
        this.hideAllStrokes()
        this.strokeSelect(name)
        this.$store.commit('setCurrentProduct', item)
        this.angleIdx = item.product_angle + 1 || 1
      }
    },
    setCanvasProp() {
      const canvas = this.stage.layer.canvasConfig
      const width = canvas.width / 2
      const height = canvas.height / 2
      canvas.x = this.getStageContainerProp("x") - width
      canvas.y = this.getStageContainerProp("y") - height
    },
    setStageSize() {
      const stage = this.stage.config
      const container = this.$refs.stageContainer
      if (!container) {
        return
      }
      const height = container.offsetHeight
      const width = container.offsetWidth
      stage.width = width
      stage.height = height
    },
    getStageContainerProp(pos) {
      const $this = this.$refs.stageContainer

      const offset = this.boundingClient
      const width = $this.clientWidth
      const height = $this.clientHeight

      const centerX = offset.left + width / 2
      const centerY = offset.top + height / 2

      if (pos == "x") {
        return centerX;
      } else if (pos == "y") {
        return centerY;
      } else if (pos == "width") {
        return width;
      } else if (pos == "height") {
        return height;
      } else {
        return;
      }
    },
    // calcHeight(value) {
    //   let numberOfLineBreaks = (value.match(/\n/g) || []).length
    //   // min-height + lines x line-height + padding + border
    //   let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2
    //   return newHeight
    // },
    handleTransformEnd(e) {
      this.saveChanges(e)
    },
    handleOnDragStart(e) {
      this.onTouchItem(e)
    },
    handleOnDragEnd(e) {
      this.saveChanges(e)
      // console.log('handleOnDragEnd')
    },
    saveChanges(e) {
      // console.log(this.currentProduct)
      if (this.currentProduct) {
        let changes = {
          ...this.currentProduct,
          coordinate_x: +e.target.x(),
          coordinate_y: +e.target.y(),
          width: e.target.width(),
          height: e.target.height() * e.target.scaleY(), // TODO
          rotation: e.target.rotation(),
          flip: e.target.scaleX() < 0 ? 1 : 0,
          clip_position: this.selectedCropStrategy,
        };
        if (this.currentProduct.type === 'figure') changes.figure_type = this.currentProduct.product.figure_type
        this.editProduct(changes)
      }
    },
    /** @type {function} */
    editProduct: debounce(function (data) {
      if (this.editAbortController) {
        this.editAbortController.abort();
      }

      const controller = new AbortController();
      this.editAbortController = controller;

      this.$api.put(`/constructor/project/layer/product/edit`, data, { signal: controller.signal })
        .then(res => {
          console.log('edit', res)
          if (res.status === 200) {
            // console.log('edited', res.data.layer)
            let updatedCurrentProduct = {
              ...this.currentProduct,
              ...res.data.layer
            }
            this.$store.commit('setCurrentProduct', updatedCurrentProduct)
            this.updateCurrentProduct(updatedCurrentProduct)
          }
        })
        .then(() => {
          this.editAbortController = null;
          this.updateImage();
        })
        .catch(error => (console.log(error)));
    }, 200),
    /** @type {function} */
    updateImage: debounce(function () {
      const image = this.stg.toDataURL();
      this.updateProjectImage({ projectId: this.projectId, image });
    }, 3000, { maxWait: 7000 }),
    handleOnMouseDown(e) {
      this.onTouchItem(e)
    },
    handleOnMouseClick(e) {
      this.transformShape = ''
      // this.selectedShapeProp = null
      this.onTouchItem(e)
    },
    getProductByShapeName(name) {
      return this.currentLayer.products.filter(el => {
        return `${this.projectId}_${el.layer_id}_${el.id}` === name
      })[0]
    },

    onTouchItem(e) {
      const name = e.target.name();
      const isTransformerTarget = e.target instanceof Konva.Transformer || e.target.parent instanceof Konva.Transformer;

      if (isTransformerTarget) return;

      this.hideAllStrokes()
      this.updateTransformer()

      if (name !== this.cropTransformShape) {
        this.cropTransformShape = ''
        this.updateCropTransformer()
      }

      if (name && this.currentLayer.products.length) {
        let prodId = name.split("_")[2]
        let findProduct = this.currentLayer.products.filter((el) => {
          return el.id === +prodId
        })[0]
        if (findProduct) {
          this.$store.commit('setCurrentProduct', findProduct)
          this.angleIdx = findProduct.product_angle + 1 || 1
        }
      }

      if (this.currentLayer) {
        let group = this.stg.findOne(".layer_" + this.currentLayer.id),
          groupShapes = group.findOne("." + name)

        if (groupShapes) {
          this.strokeSelect(name)
          // let shape = this.getProductByShapeName(name)
          // console.log(groupShapes);
        }
      }
    },
    strokeSelect(name) {
      // this.hideAllStrokes()
      this.currentShape = name
      let shape = this.findShape(name)
      if (shape) {
        shape.setAttrs({
          shadowColor: '#53abd2',
          shadowBlur: 7,
          shadowOffset: {x: 0, y: 0},
          shadowOpacity: 1
        })
        this.selectedShapeProp.props = shape;
      }
    },
    hideAllStrokes() {
      if (this.preventHideStrokes) {
        return;
      }

      this.currentShape = ''
      let groups = this.stg.find('Group')
      let groupsShapes = groups.slice(0, -1).map((el) => {
        return el.children
      })
      let allShapes = [].concat.apply([], groupsShapes)

      allShapes.forEach((el) => {
        el.setAttrs({
          shadowOpacity: 0,
        })
      })
    },

    convertBlobToImg(product) {
      let url = product.shape.image
      let img = new window.Image()
      img.crossOrigin = 'Anonymous';
      img.src = url
      return img
    },
    filterCatalog(filter) {
      if (filter) {
        this.$emit('filter', filter)
        this.$scrollTo('.filters', 800)
      }
    },
    addProduct(data) {
      this.$api.post(`/constructor/project/layer/product/add `, data)
        .finally(() => this.getLayers())
    },
    getLayers() {
      if (this.projectId) {
        this.$store.dispatch('getLayers', this.projectId)
      }
    },
    downloadURI(uri, name) {
      let link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    saveStage() {
      this.hideAllStrokes()
      let dataURL = this.$refs.mainStage.getStage().toDataURL()
      this.downloadURI(dataURL, "gs3d.png")
    },
    applyShapesAttrs() {
      for (const layer of this.shapes) {
        for (const product of layer.products) {
          if (!product.flip && !product.clip_position) continue;

          const shapeName = `${this.projectId}_${layer.id}_${product.id}`;
          const shape = this.findShape(shapeName);
          if (!shape) continue;

          if (product.flip) {
            shape.setAttrs({ scaleX: -1 });
          }

          if (product.clip_position) { // TODO set crop initially before first render
            this.applyCrop(product.clip_position, shape);
          }
        }
      }
    },
    addToInspiration() {
      let dataURL = this.$refs.mainStage.getStage().toDataURL()
      let data = {
        image: dataURL,
        project_id: this.currentProject.project.id
      }
      this.$emit('to-inspiration', data)
    },
  },
};
</script>

<style scoped>
  .texteditor {
    position: absolute;
  }

  .textarea-editor {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    resize: none;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    user-select: text;
    word-break: normal;
    background: transparent;
    outline: none;
    border: 1px solid #9DDBFF;
  }
</style>

